import { formatWeiToEth } from "@unlockdfinance/verislabs-web3/utils";

export { healthFactorDescription } from "./healthFactorDescription";
export { approveInWalletLiteral } from "./approveInWalletLiteral";

const genericRepayApprovalLifecycleLiterals = {
  onSignPending: "Time to approve. Please sign off in your wallet.",
  onTxPending: "We're getting things done. Give us a moment...",
  onSuccess: "Ready to go! Please sign the repayment transaction.",
  onError:
    "Something went wrong with the approval. Please retry or contact support.",
};

export const lifeCycleLiterals = {
  general: {
    userRejectedTx:
      "You rejected the transaction. Let's try again or check if you need any help.",
    onError: "Unexpected error while connecting",
  },
  createWallet: {
    onSignPending:
      "Your Unlockd Account is almost ready. Confirm by signing in your wallet.",
    onTxPending: "Hang tight! We're crafting your Unlockd Account right now.",
    onError:
      "Something went wrong creating your Unlockd Account. Please retry or contact support.",
    onSuccess: "Success! Your Unlockd Account is ready to go!",
  },
  approveCollections: {
    onSignPending: "Almost there! Please sign the transaction in your wallet.",
    onTxPending:
      "Transaction in progress. Your collection will be ready shortly.",
    onError: "Something went off track. Please retry or contact support.",
    onSuccessCollection: (collectionName: string) =>
      `Success! ${collectionName} is now approved and ready for deposit.`,
  },
  bulkTransfer: {
    onSignPending:
      "Almost there! Sign off on your wallet to confirm the bulk transfer.",
    onTxPending:
      "Your bulk transfer is on the move. Just a bit longer and we'll be done.",
    onError:
      "Something went wrong with the bulk transfer. Please retry or contact support.",
    onSuccess:
      "All set! Your NFTs have been successfully added to your Unlockd Account.",
  },
  createLoan: {
    amountExceedsLiquidity:
      "This amount exceeds our current liquidity. Try a smaller loan value.",
    amountExceedsAvailable:
      "Whoa there! That amount exceeds your borrow limit.",
    noAmountSelected:
      "Oops, you forgot to enter a loan amount. Let's fix that.",
    noNftsSelected: "Hold up! You need to add some NFTs to secure your loan.",
    onServerSignPending: "Finalizing your loan setup. Just a moment...",
    onSignPending:
      "Your loan is almost ready. Please sign the transaction to proceed.",
    onTxPending:
      "Crafting your loan with care. Hang tight, we're almost there.",
    onError: "Unable to process the loan. Please retry or contact support.",
    onSuccess: "Loan created successfully! Funds are on their way.",
  },
  borrowMore: {
    onServerSignPending: "Preparing your funds. Just a moment...",
    onSignPending:
      "Your funds are almost ready. Confirm by signing in your wallet.",
    onTxPending: "Processing the transfer. Funds on their way!",
    onError: "Couldn't borrow more funds. Please retry or contact support.",
    onSuccess: "Cha-ching! Funds are all yours now. Happy spending or saving!",
  },
  withdraw: {
    amountIsZero:
      "Looks like you missed the amount. Enter something to withdraw.",
    amountExceedsDepositedBalance:
      "Your entered amount is more than what you have deposited in the pool. Try a lower value.",
    amountExceedsLiquidity: (
      liquidity: bigint
    ) => `There is not enough liquidity at this moment. The maximum amount you can withdraw is ${formatWeiToEth(
      liquidity,
      18
    )}.
                  This is temporary, try again later.
                  However, be aware that the less liquid the protocol is, the higher the APY`,
    noLiquidity:
      "There is not enough liquidity at this moment. This is temporary, try again later. However, be aware that the less liquid the protocol is, the higher the APY",
    onSignPending: "Confirm in your wallet to complete your withdrawal.",
    onTxPending: "Withdrawing your tokens. Final steps underway!",
    onError:
      "There was an issue with your withdrawal. Retry or contact support.",
    onSuccess: "Withdrawn! Your tokens are back in your wallet.",
  },
  approveWithdraw: {
    onSignPending: "Ready to withdraw? Sign off to approve first.",
    onTxPending: "We're getting things done. Your approval is almost ready.",
    onError: "There was an issue with your approval. Retry or contact support.",
    onSuccess: "Approved. Now you can withdraw!",
  },
  supply: {
    onSignPending: "Almost there! Confirm the supply in your wallet.",
    onTxPending:
      "Depositing your tokens. Hold on while they flow through the liquidity pipelines.",
    onError: "There was an issue with your supply. Retry or contact support.",
    onSuccess: "Deposited! Your tokens are now fueling the pool.",
    amountIsZero:
      "Oops, you haven't entered an amount. Add some tokens to supply.",
    amountExceedsBalance:
      "This amount exceeds your current wallet balance. Please enter a smaller number.",
  },
  approveSupply: {
    onSignPending: "Time to approve. Please sign off in your wallet.",
    onTxPending:
      "Finalizing approval. Your tokens are almost ready for the pool.",
    onError:
      "Something went wrong with your approval. Retry or contact support.",
    onSuccess: "Token approved. You can now supply to the pool.",
  },
  addNfts: {
    noNftsSelected:
      "Looks like no NFTs were chosen. Pick some to add to your loan.",
    onServerSignPending: "Prepping to add NFTs to your loan. Just a moment...",
    onSignPending: "Ready to add NFTs. Confirm by signing in your wallet.",
    onTxPending: "Adding NFTs to your loan. Hang tight, we're almost there.",
    onError: "Couldn't add NFTs. Please retry or reach or contact support.",
    onSuccess:
      "NFTs added to your loan successfully! Check your updated loan details.",
  },
  instantSell: {
    onServerSignPending: "Preparing your instant sell. Just a moment...",
    onSignPending: "Ready to go! Sign the transaction to confirm instant sell.",
    onTxPending: "Getting things done! Hang tight, we're almost there.",
    onError: "Instant sell failed. Please retry or contact support.",
    onSuccess: "Cha-ching! Funds are all yours now. Happy spending or saving!",
  },
  listForSale: {
    errorOnSubmit: "Check your inputs. Something's not quite right.",
    startingPriceExceedsListing:
      "Your starting price is above your listing. Let's adjust it.",
    startingPriceIsZero: "You need to set a starting price greater than 0.",
    debtListingExceeds100Percent: "Debt listing percentage can't exceed 100%.",
    startingPriceLessThanDebt:
      "Starting price must be higher than the debt amount. ",
    listingPriceLessThanDebt:
      "Buyout price must cover the listed debt. Please enter a higher amount.",
    listingPriceIsZero:
      "Price can't be zero. Set a value to make the deal worthwhile for you.",
    onServerSignPending: "We're setting up your listing. Just a moment...",
    onSignPending:
      "Ready to go! Sign the transaction to list your asset for sale",
    onTxPending: "Just a moment while we put the finishing touches...",
    onError:
      "Something went wrong with the listing. Please retry or contact support.",
    onSuccess:
      "Your asset is now live in the Marketplace. Fingers crossed for a swift sale!",
  },
  cancelList: {
    onSignPending: "Ready to go! Sign the transaction to cancel your listing.",
    onTxPending: "Getting things done! Hang tight, we're almost there.",
    onError: "Couldn't cancel the listing. Please retry or contact support.",
    onSuccess:
      "Listing cancelled successfully. Your asset is no longer up for sale in the Marketplace.",
  },
  approvalRepay: {
    onSignPending: genericRepayApprovalLifecycleLiterals.onSignPending,
    onTxPending: genericRepayApprovalLifecycleLiterals.onTxPending,
    onError: genericRepayApprovalLifecycleLiterals.onError,
    onSuccess: "Ready to go! Please sign the repayment transaction.",
  },
  repay: {
    amountExceedsBalance:
      "Your wallet balance is insufficient for this repayment amount. Try with a smaller amount.",
    amountLessThaMinimum:
      "Increase your amount to meet the minimum required for this auctioned loan.",
    amountIsZero: "Enter an amount to repay. It can't be zero.",
    onServerSignPending: "Getting your repayment ready. Just a moment...",
    onSignPending: "Ready to go! Please sign the repayment transaction.",
    onTxPending: "Processing your payment. Coins are rolling back to us!",
    onError:
      "Something went wrong with the repayment. Please retry or contact support.",
    onSuccess: "Payment received. You're now lighter on debt!",
  },
  placeABid: {
    bidExceedsBalance:
      "Your bid can't exceed your wallet balance. Try a smaller amount.",
    bidLessThanMin:
      "That bid's too low. Please enter an amount higher than the minimum bid.",
    initialPayExceedsBid:
      "Initial payment can't exceed your total bid. Adjust it to match or be lower.",
    initialPayLessThanMin:
      "Initial payment is below the minimum required. Please increase the amount",
    initialPaymentExceedsBalance:
      "You can't downpay exceeding your wallet balance.",
    onServerSignPending: "Getting your bid ready. Just a moment... ",
    onSignPending:
      "Your bid's lined up. Please sign in your wallet to confirm.",
    onTxPending:
      "Almost there! We're minting your bid into reality. Hang tight!",
    onError: "Unable to process the bid. Please retry or contact support.",
    onSuccess: "Bid placed with style! Fingers crossed for a winning move.",
  },
  buyNow: {
    initialPayLessThanMin:
      "Initial payment is below the minimum required. Please increase the amount",
    initialPayExceedsPrice:
      "Your initial payment exceeds the price. Please enter a lower amount.",
    initialPayEqualPrice:
      "Your initial payment is equal to the instant buy price.",
    onServerSignPending: "Preparing your purchase. Just a moment...",
    onSignPending: "NFT awaits! Just need your signature to seal the deal.",
    onTxPending: "Locking in your NFT! A moment more and it's all yours",
    onError: "Unable to process the purchase. Please retry or contact support.",
    onSuccess: "NFT acquired! Welcome to your newest digital treasure.",
  },
  claim: {
    onServerSignPending: "Setting up your NFT claim. Just a moment...",
    onSignPending: "Sign off in your wallet to claim your NFT.",
    onTxPending:
      "Claiming your prize! Your NFT is just a mint away from being yours.",
    onError: "Unable to process the claim. Please retry or contact support.",
    onSuccess: "NFT claimed! Time to celebrate your new digital masterpiece.",
  },
  removeNfts: {
    amountLessThanMin:
      "The amount entered is below the minimum repayment. Please increase it.",
    amountExceedsDebt:
      "Whoa there! That amount exceeds your current debt. Try smaller.",
    noNftsSelected: "Select the NFTs you want to remove from your loan.",
    onServerSignPending: "Preparing to remove NFTs. Just a moment...",
    onSignPending:
      "Your removal is almost ready. Confirm by signing in your wallet.",
    onTxPending:
      "Removing NFTs from your loan. Hang tight, we're almost there.",
    onError: "Couldn't remove NFTs. Please retry or contact support.",
    onSuccess:
      "NFTs removed from your loan successfully! Check your updated loan details.",
  },
  sendTokens: {
    onSignPending: "Almost there! Please sign the transaction in your wallet.",
    onTxPending: "We're getting your transfer ready. Just a moment...",
    onError: "Unable to process withdrawal. Please retry or contact support.",
    onSuccess: "Success! Funds are leaving your Account right now.",
  },
  withdrawNfts: {
    onSignPending: "We're almost there. Sign in your wallet to proceed.",
    onTxPending: "We're getting your transfer ready. Just a moment...",
    onError: "Unable to process withdrawal. Please retry or contact support.",
    onSuccess: "Success! Assets are leaving your Account right now.",
  },
  pushSubscribe: {
    onSignPending:
      "Ready to stay informed. Please sign to subscribe to push notifications.",
    onError: "Couldn't activate push notifications. Retry or contact support.",
    onSuccess:
      "You're all set! Push notifications activated. Stay tuned for timely updates.",
  },
  pushUnsubscribe: {
    onSignPending:
      "Confirm your choice. Please sign to unsubscribe from push notifications.",
    onError: "Couldn't disable push notifications. Retry or contact support.",
    onSuccess:
      "Unsubscribed successfully. You won't receive push notifications anymore.",
  },
  approvalRedeem: {
    onSignPending: genericRepayApprovalLifecycleLiterals.onSignPending,
    onTxPending: genericRepayApprovalLifecycleLiterals.onTxPending,
    onError: genericRepayApprovalLifecycleLiterals.onError,
    onSuccess: "Ready to go! Please sign the repayment transaction.",
  },
};

export const generalLiterals = {
  // borrow: {
  //   subheader: "Use your Unlockd Account to borrow seamlessly.",
  // },
  createLoan: {
    nftsSelectorTitle: "My Available NFTs:",
    borrowApyLabel: "Interest APR",
    amountLabel: "Amount to Borrow",
    healthFactorLabel: "Health Factor",
    liquidationPriceLabel: "Liquidation price",
    avToBorrowLabel: "Available to borrow",
    valuationLabel: "Valuation",
    submitCta: "Start Borrowing",
    minToPayLabel: "Min. to Pay",
    totalDebtLabel: "Total Debt",
  },
  myAvailableNfts: {
    title: "My Supported Assets",
    subheader: "Deposit one or multiple NFTs to initiate a loan.",
    descriptionWhenEmptyL1: "No NFTs in your Unlockd Account yet.",
    descriptionWhenEmptyL2:
      "Deposit from your personal wallet to start borrowing.",
    faucet: "Go to Faucet",
    borrowCta: "Create Loan",
    feedbackClickDisabledNft:
      "Can’t mix ETH-backed assets with USDC-backed assets",
    feedbackNotValuted: "No Pricing Data available for this asset",
  },
  createWallet: {
    title: "Create your Unlockd Account",
    description:
      "Here's where you can deposit your NFTs, create and manage loans, and keep track of your assets.",
    submitCta: "Create Account",
  },
  fundYourUnlockdAccount: {
    title: "Fund your Unlockd Account",
    path: "fund-your-unlockd-account",
    subheader:
      "Choose NFTs to deposit from your wallet into your Unlockd Account",
    nftsSelectorTitle: "My Supported NFTs:",
    selectedNftsTitle: "Selected NFTs",
    cta: "Fund Unlockd Account",
  },
  modalApprovals: {
    title: "Approve Collections",
    subheader:
      "Each collection requires individual approval to proceed. This step ensures the secure transfer of your NFTs into your Unlockd Account.",
    nftsSelectorTitle: "My Supported NFTs:",
  },
  myLoans: {
    title: "My Active Loans",
    nftsLabel: "NFTs",
    loanIdLabel: "Loan ID",
    valuationLabel: "Valuation",
    liquidationPriceLabelL1: "Liquidation",
    liquidationPriceLabelL2: "Price",
    healthFactorLabelL1: "Health",
    healthFactorLabelL2: "Factor",
    borrowedAmountLabel: "Borrowed Amount",
    avToBorrowLabelL1: "Available to",
    avToBorrowLabelL2: "Borrow",
    myLoan: {
      dropdown: {
        repayLabel: "Repay",
        addNftsLabel: "Add NFTs",
        removeNftsLabel: "Remove NFTs",
        instantSellLabel: "Instant Sell",
        borrowMoreLabel: "Borrow More",
      },
    },
  },
  dashboard: {
    protocolMetrics: {
      title: "Protocol Metrics",
      tvlLabel: "Total Value Locked",
      nftsDepositedLabel: "Total NFTs Deposited",
      totalNftsValueLabel: "Total NFT Value",
      utilizationRateLabel: "Utilization Rate",
    },
    loansBox: {
      title: "My Loans",
      nftsCollateralizedLabel: "NFTs collateralized",
      amountBorrowedLabel: "Amount borrowed",
      interestRateLabel: "Interest rate",
      borrowLinkLabel: "Borrow",
    },
    earnBox: {
      title: "My Earnings",
      fundsSuppliedLabel: "Funds supplied",
      interestEarnedLabel: "Interest earned",
      apyLabel: "Lending APY",
      earnLinkLabel: "Earn",
    },
    auctionsBox: {
      title: "My Auctions",
      ongoingLabel: "Ongoing",
      claimedLabel: "Claimed",
      myActivityLinkLabel: "My Activity",
    },
    collectionsPanel: {
      title: "Collections",
      totalNftsLockedLabel: "Total NFTs Locked",
      tvlLabel: "TVL",
      valuationRangeLabel: "Valuation Range",
      seeMoreCtaLabel: "See More",
    },
  },
  earn: {
    assetLabel: "Asset",
    supplyCta: "Deposit",
    withdrawCta: "Withdraw",
    apyLabel: "Est. APY",
    balanceLabel: "Wallet Balance",
    depositedLabel: "Deposited",
    ethLabel: "ETH",
    tvlLabel: "Total Supplied",
    apyPlaceholder: "0%",
    balancePlaceholder: "--",
    utilzationRateLabel: "Utilization Rate",
  },
  earnForm: {
    supply: {
      title: "Supply",
      subheader: "Add tokens to the pool and start earning.",
      inputLabel: "Amount to supply",
      ctaPrimaryLoading: "Loading...",
      ctaPrimaryApprove: "Approve",
      ctaPrimarySupply: "Supply",
    },
    withdraw: {
      title: "Withdraw",
      subheader:
        "Withdraw previously deposited tokens from the liquidity pool.",
      inputLabel: "Amount to withdraw",
      ctaPrimaryLoading: "Loading...",
      ctaPrimaryApprove: "Approve",
      ctaPrimaryWithdraw: "Withdraw",
    },
    succeedMode: {
      ctaPrimary: "Close",
      messageL1: "Transaction Submitted",
      messageL2: "View on Etherscan:",
    },
    ctaPrimaryLoading: "Loading",
    backButton: "Back",
    loadingMessageL1: "Don’t close this screen.",
    loadingMessageL2:
      "Your transaction should be confirmed in the blockchain shortly.",
  },
  listForSale: {
    title: "Create a listing for your asset in the Unlockd Marketplace",
    fixedPriceDescription:
      "Set a fixed selling price for your asset. The first buyer to match it acquires the asset, and you receive the agreed amount.",
    auctionDescription:
      "Set a minimum starting price for your asset. Users can place bids. Once the duration ends, the asset goes to the highest bidder, and you receive the winning bid amount.",
  },
  modals: {
    buyNow: {
      title: "Buy Now",
      subheader: "Purchase or finance this NFT instantly.",
      payFullPriceLabel: "Pay Full Price",
      payLaterLabel: "Finance With Loan",
      ctaPrimaryNotEnoughBalance: "Not enough balance",
      ctaPrimaryCreateWallet: "Create Account",
      ctaPrimaryApprove: "Approve",
      ctaPrimaryBuyNow: "Buy Now",
      ctaPrimaryConfirmLoan: "Confirm Loan",
      liquidationPriceLabel: "Liquidation Price",
      initialPaymentInputLabel: "Down Payment",
      minPaymentLabel: "Min. Down Payment",
      debtToTakeLabel: "Remaining Debt After Downpayment:",
      healthFactorLabel: "Health Factor:",
    },
    bid: {
      title: "Place Your Bid",
      subheader:
        "Submit your bid by committing funds now. Opt for full payment or use Bid-Now-Pay-Later for financing with a loan.",
      payFullPriceLabel: "Pay Full Price",
      payLaterLabel: "Finance With Loan",
      minBidLabel: "Minimum Bid",
      ctaPrimaryCreateWallet: "Create Account",
      ctaPrimaryApprove: "Approve",
      ctaPrimaryBid: "Place Bid",
      ctaPrimaryInvalidBid: "Invalid Bid",
      bidAmountInputLabel: "Bid Amount",
      totalBidAmountInputLabel: "Total Bid Amount",
      initialPaymentInputLabel: "Down Payment",
      minPaymentLabel: "Min. Down Payment",
    },
    borrowMore: {
      title: "Borrow More",
      subheader: "Increase your loan amount",
      valuationLabel: "Valuation",
      liqPriceLabel: "Liquidation Price",
      alreadyBorrowedLabel: "Already Borrowed",
      avToBorrowLabel: "Available to Borrow",
      hfLabel: "Health Factor",
      submitCta: "Borrow",
      backCta: "Back",
    },
    redeem: {
      hfLabel: "Health Factor",
      minToRepayLabel: "Min to repay",
      title: (currencyLabel: string) => `Repay ${currencyLabel}`,
      subheader: "Repay in full to save your assets",
      redeemCta: "Repay",
      approveCta: "Approve",
      notEnoughBalanceCta: "Not Enough Balance",
      debtLabel: "Current Debt: ",
      backCta: "Back",
    },
    addNfts: {
      title: "Add NFTs",
      subheader: "Select additional NFTs to add to your loan.",
      submitCta: (amountOfNfts: number) =>
        `Add ${amountOfNfts} NFT${amountOfNfts > 1 ? "s" : ""}`,
      fundCta: "Fund Unlockd Account",
      hfLabel: "Health Factor",
      avToBorrowLabel: "Available to Borrow",
    },
    removeNfts: {
      title: "Remove NFTs",
      subheader:
        "Remove NFTs to free them up in your Unlockd Wallet for new loans.",
      hfLabel: "Health Factor",
      totalDebtLabel: "Total Debt",
      minToPayLabel: "Required Repayment",
      removeCta: "Remove",
      approveCta: "Approve",
      backCta: "Cancel",
    },
    supply: {
      title: "Supply ETH",
      subheader: "Add tokens to the pool and start earning.",
      ctaPrimaryLoading: "Loading...",
      ctaPrimaryApprove: "Approve",
      ctaPrimarySupply: "Supply",
    },
    withdraw: {
      title: "Withdraw ETH",
      subheader:
        "Withdraw previously deposited tokens from the liquidity pool.",
      ctaPrimaryLoading: "Loading...",
      ctaPrimaryApprove: "Approve",
      ctaPrimaryWithdraw: "Withdraw",
    },
    instantSell: {
      title: "Instant Sell",
      subheader:
        "Unlockd finds the highest marketplace bid. You can sell if it keeps your Health Factor above 1.",
      sellPriceLabel: "Highest Offer",
      noOffersDescription: "No offers available for this asset",
      backCta: "Back",
      amountToReceiveLabel: "You will receive:",
      hfLabel: "New Health Factor:",
      sellCta: "Sell",
      sellCtaOnHfLow: "HF Too Low",
      collectionLabel: "Collection",
      tokenIdLabel: "Token ID",
      debtLabel: "Total debt",
    },
  },
  marketplaceLayout: {
    titleGeneral: "Marketplace",
    titleItemDetails: "Asset Details",
    titleListForSale: "List for sale",
    subheader:
      "Explore, buy and bid on NFTs for sale or in liquidation and leverage Bid-Now-Pay-Later options.",
    navBar: {
      buyNfts: "Buy NFTs",
      activeLoans: "Active Loans",
      transactionHistory: "Transaction History",
      myActivity: "My Activity",
    },
  },
  marketplace: {
    itemCard: {
      buyNowCta: "Buy Now",
      placeBidAuctionCta: "Place Bid",
    },
  },
};
